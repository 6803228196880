import React, { useState } from 'react';
import { StaticImage } from "gatsby-plugin-image";

import useApi from '../../utils/useAPI';
import { Spinner } from '../../components/Spinner';
import { trackPageView, TrackingScript } from '../../components/Layout';

const RepRequest = () => {

    const [emailAddress, setEmailAddress] = useState("");
    const [error, setError] = useState("");
    const { loading, data, error: apiError, trigger } = useApi();


    const updateEmailAddress = (e) => {
        setError("");
        setEmailAddress(e.target.value.toLowerCase());
    }
    const submitRequest = () => {
        var re = /\S+@\S+\.\S+/;
        if (emailAddress === "" || !re.test(emailAddress)) {
            setError("Please enter a valid email address");
            return;
        }

        const url = `reps/request-access?email=${emailAddress}`
        trigger(url);
    }
    const getEmailMessage = () => {
        if (error) {
            return error;
        }
        if (apiError) {
            return apiError;
        }
        if (!loading && data) {
            if (!data.items || data?.items.length == 0 ) {
                return "That email wasn't found. Try a better one.";
            }
            if (data.items.length > 0) {
                return "Email address located. Your access code is being sent to that email address.";
            }
        }
    }

    return <div className='rep-request-container'>
        <TrackingScript />
        <StaticImage
            src="../../images/VanWalksLogo.png"
            width={300}
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="VanWalks Logo"
            className="logo"
            backgroundColor="transparent"
            placeholder="none"
        />
        <h1>VanWalks Rep Access</h1>
        <label>Enter your Email Address:</label>
        <input type={"text"} onChange={updateEmailAddress} />
        { <span className={ getEmailMessage()?.indexOf("Email address located") > -1 ? "success" : "error"}>{ getEmailMessage() }</span> }
        <button onClick={submitRequest}>Submit { loading && <Spinner /> }</button>
        { trackPageView() }
    </div>
}
export default RepRequest;